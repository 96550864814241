import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'

// Images
import Leaf from 'img/leaf.inline.svg'
import Phone from 'img/phone.inline.svg'
import Email from 'img/email.inline.svg'
import NavLink from 'components/shared/NavLink'
import Logo from 'img/logo.inline.svg'
import Wave from 'img/footerwave.inline.svg'

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.color.primary};
  color: ${(props) => props.theme.color.light};

  & h2 {
    font-size: ${({ theme }) => theme.font.size.bigger};
    font-weight: ${({ theme }) => theme.font.weight.bold};

    @media (min-width: 576px) {
      margin-bottom: 2.5rem;
    }
  }

  & ul {
    list-style: none;
    padding-left: 0;

    & li {
      margin-bottom: 6px;
    }
  }

  & a {
    color: ${({ theme }) => theme.color.light} !important;
    &:hover {
      text-decoration: underline;
    }
  }
`

const Brand = styled(NavLink)`
  @media (min-width: 992px) {
    & > svg {
      height: 53px;
      width: 220px;
    }
  }

  @media (max-width: 991.98px) {
    & > svg {
      height: 37px;
      width: 160px;
    }
  }
`

const Documents = styled.ul`
  & li:last-child {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      top: 10.5px;
      left: 120%;
      width: 2000px;
      height: 2px;
      background-color: ${({ theme }) => theme.color.light};
    }
  }
`

const SVGWrapper = styled.div`
  z-index: 2;
  right: 0;
  top: -100px;
`

const Container = styled.div`
  z-index: 3;
`

const Footer = () => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      fields: wpComponent(databaseId: { eq: 98 }) {
        footer {
          menuFooter {
            link {
              title
              url
              target
            }
          }
          portalsFooter {
            link {
              title
              url
              target
            }
          }
          contactFooter {
            phone {
              title
              url
            }
            email {
              title
              url
            }
          }
          infoFooter {
            link {
              title
              url
              target
            }
          }
          documentsFooter {
            link {
              title
              url
              target
            }
          }
        }
      }
    }
  `)

  return (
    <Wrapper className="position-relative">
      <SVGWrapper className="position-absolute">
        <Wave />
      </SVGWrapper>
      <Container className="position-relative container py-5">
        <div className="row justify-content-between">
          <div className="col-lg-7">
            <div className="row py-3">
              <div className="col-6 col-sm-4 mb-4 mb-sm-0">
                <h2>Menu</h2>
                <ul>
                  {fields?.footer?.menuFooter?.map((node, index: number) => (
                    <li key={index}>
                      <Link
                        target={node?.link?.target}
                        to={node?.link?.url || '#'}
                      >
                        {node?.link?.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-6 col-sm-4">
                <h2>Portals</h2>
                <ul>
                  {fields?.footer?.portalsFooter?.map((node, index) => (
                    <li key={index}>
                      <Leaf />
                      <Link
                        target={node?.link?.target}
                        to={node?.link?.url || '#'}
                        className="ms-2"
                      >
                        {node?.link?.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-sm-4 mb-4 mb-sm-0">
                <h2>Contact</h2>
                <ul>
                  <li className="d-flex align-items-center">
                    <Phone />
                    <Link
                      to={fields?.footer?.contactFooter?.phone?.url || '#'}
                      className="ms-2"
                    >
                      {fields?.footer?.contactFooter?.phone?.title}
                    </Link>
                  </li>
                  <li className="d-flex align-items-center">
                    <Email />
                    <Link
                      to={fields?.footer?.contactFooter?.email?.url || '#'}
                      className="ms-2"
                    >
                      {fields?.footer?.contactFooter?.email?.title}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <Brand to="/">
              <Logo />
            </Brand>
            <ul className="row mt-4">
              {fields?.footer?.infoFooter?.map((node, index) => (
                <li className="col-6" key={index}>
                  <span>{node?.link?.title}</span>
                </li>
              ))}
            </ul>
            <Documents className="d-flex justify-content-between mt-5 pt-5">
              {fields?.footer?.documentsFooter?.map((node, index) => (
                <li key={index}>
                  <NavLink to={node?.link?.url || '#'}>
                    {node?.link?.title}
                  </NavLink>
                </li>
              ))}
            </Documents>
          </div>
        </div>
        <ul></ul>
      </Container>
    </Wrapper>
  )
}

export default Footer
