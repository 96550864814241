/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import NavLink from 'components/shared/NavLink'
import Submenu from 'components/layout/Header/Submenu'

// Images
import ChevronDesktop from 'img/chevron.inline.svg'
import ChevronMobile from 'img/chevron-secondary.inline.svg'

// Third Party
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

const Item = styled(motion.li)`
  & > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  & div > a {
    color: ${(props) => props.theme.color.light};
    &[aria-current] {
      color: ${({ theme }) => theme.color.secondary};
    }

    &:hover {
      color: ${({ theme }) => theme.color.secondary};
      opacity: 0.7;
    }
  }

  @media (min-width: 1400px) {
    margin-left: 45px;
    font-size: ${({ theme }) => theme.font.size.medium};
  }

  @media (min-width: 992px) and (max-width: 1399px) {
    font-size: ${({ theme }) => theme.font.size.small};
    margin-left: 35px;
  }

  @media (max-width: 1199.98px) {
    margin-left: 20px;
  }

  @media (max-width: 991px) {
    padding: 4px 0;

    & div > a {
      font-size: 20px;
      line-height: 20px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }

    &:last-child {
      margin-top: 25px;
    }
  }
`

interface MenuProps {
  fields: GatsbyTypes.WpComponent
}

const SVGWrapper = styled(motion.div)<{ open: boolean }>`
  margin-left: 5px;

  & > svg {
    transition: all 0.3s ease;

    ${({ open }) =>
      open
        ? css`
            transform: rotate(180deg);
          `
        : css`
            transform: rotate(0deg);
          `};
  }
`

const Menu: React.FC<MenuProps> = ({ fields }) => {
  const [open, setOpen] = React.useState<number>(-1)

  return (
    <>
      {fields.header?.menuHeader?.map((l, index) => (
        <Item
          onHoverStart={() => setOpen(index)}
          onHoverEnd={() => setOpen(-1)}
          key={index}
        >
          <div className="d-flex align-items-center">
            {l?.highlighted ? (
              <ButtonPrimary
                target={l.link?.target}
                to={l.link?.url || '#'}
                compact
              >
                {l?.link?.title}
              </ButtonPrimary>
            ) : (
              <NavLink target={l?.link?.target} to={l?.link?.url || '/'}>
                {l?.link?.title}
              </NavLink>
            )}
            {l?.submenu && (
              <>
                <SVGWrapper
                  onClick={
                    open === index ? () => setOpen(-1) : () => setOpen(index)
                  }
                  open={open === index}
                  className="d-none d-lg-block"
                >
                  <ChevronDesktop />
                </SVGWrapper>
                <SVGWrapper
                  onClick={
                    open === index ? () => setOpen(-1) : () => setOpen(index)
                  }
                  initial={{ rotate: 90 }}
                  open={open === index}
                  className="d-block d-lg-none ms-3"
                >
                  <ChevronMobile />
                </SVGWrapper>
              </>
            )}
          </div>
          {l?.submenu && (
            <AnimatePresence>
              {open === index && (
                <Submenu
                  fields={fields.header?.contactHeader}
                  submenu={l.submenu}
                  title={l.link?.title || ''}
                />
              )}
            </AnimatePresence>
          )}
        </Item>
      ))}
    </>
  )
}

export default Menu
