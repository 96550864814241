import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'

// Elements
import NavLink from 'components/shared/NavLink'

const Item = styled(motion.li)`
  & > a {
    color: ${({ theme }) => theme.color.light} !important;

    &[aria-current='page'] {
      color: ${({ theme }) => theme.color.secondary} !important;
    }

    &:hover {
      color: ${({ theme }) => theme.color.secondary} !important;
    }
  }

  @media (min-width: 992px) {
    &:last-child:after {
      position: absolute;
      content: '';
      background: ${({ theme }) => theme.color.light};
      top: 12px;
      width: 2000px;
      height: 2px;
      left: 120%;
    }
  }
`

const Icon = styled(Plaatjie)``

interface TopmenuProps {
  fields: GatsbyTypes.WpComponent
}

const Topmenu: React.FC<TopmenuProps> = ({ fields }) => {
  const [currentIndex, setCurrentIndex] = useState(-1)

  return (
    <>
      {fields.header?.topmenuHeader?.map((node, index) => (
        <Item
          className="position-relative"
          key={index}
          onHoverStart={() => setCurrentIndex(index)}
          onHoverEnd={() => setCurrentIndex(-1)}
        >
          <NavLink
            to={node?.link?.url || '#'}
            className="d-flex align-items-center"
          >
            <motion.span
              className="d-inline-flex align-items-center"
              animate={currentIndex == index ? { x: 3 } : { x: 0 }}
              transition={
                currentIndex == index
                  ? { yoyo: Infinity, duration: 0.5 }
                  : { yoyo: null }
              }
            >
              <Icon image={node?.icon} alt="" className="me-2" />
            </motion.span>
            <span>{node?.link?.title}</span>
          </NavLink>
        </Item>
      ))}
    </>
  )
}

export default Topmenu
