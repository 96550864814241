import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { motion } from 'framer-motion'

// Elements
import NavLink from 'components/shared/NavLink'

// Images
import Chevron from 'img/chevron-button.inline.svg'

interface CtaFixedProps {
  isSticky?: boolean
}

const Wrapper = styled(motion.div)`
  z-index: 99;
  transform: rotate(-90deg);
  left: -117px;
  top: 60%;

  & > a {
    padding: 20px 25px 20px 40px;
    background-color: #e2ebf4;
    border-bottom-left-radius: 50px;
    font-weight: ${({ theme }) => theme.font.weight.bold};

    & > span {
      color: ${({ theme }) => theme.color.primary};
    }
  }

  & svg {
    width: 10px;
    height: 22px;
    margin-left: 12px;
  }
`

const CtaFixed: React.FC<CtaFixedProps> = ({ isSticky }) => {
  const [hovered, setHovered] = useState(false)

  // eslint-disable-next-line
  const { fields } =
    useStaticQuery<GatsbyTypes.fixedElementsQueryQuery>(graphql`
      query fixedElementsQuery {
        fields: wpComponent(databaseId: { eq: 506 }) {
          fixedElements {
            cta {
              title
              url
              target
            }
          }
        }
      }
    `)

  return (
    <Wrapper
      className="position-fixed d-none d-sm-block"
      onHoverStart={() => setHovered(true)}
      onHoverEnd={() => setHovered(false)}
    >
      <NavLink
        target={fields?.fixedElements?.cta?.target}
        to={fields?.fixedElements?.cta?.url || '#'}
      >
        <span>{fields?.fixedElements?.cta?.title}</span>
        <motion.span
          className="d-inline-block"
          animate={hovered ? { x: 15 } : { x: 5 }}
        >
          <Chevron />
        </motion.span>
      </NavLink>
    </Wrapper>
  )
}

export default CtaFixed
