const mainTheme = {
  color: {
    primary: '#004B96',
    secondary: '#A8C855',
    grey: '#D1D4DD',
    dark: '#1C1C1C',
    light: '#FFFFFF',
  },

  font: {
    family: {
      primary: 'aller, sans-serif',
      secondary: 'aller, sans-serif',
    },

    size: {
      tiny: '12px',
      small: '14px',
      medium: '16px',
      big: '18px',
      bigger: '20px',
      large: '24px',
      larger: '28px',
      huge: '36px',
      ultra: '43px',
      mega: '48px',
      giga: '60px',
      master: '110px',
    },

    weight: {
      light: 300,
      regular: 400,
      semiBold: 500,
      bold: 600,
      extraBold: 800,
    },
  },
}

export default mainTheme
