/* eslint-disable import/no-unresolved */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { motion } from 'framer-motion'

// Images
import Logo from 'img/logo.inline.svg'

// Components
import NavLink from 'components/shared/NavLink'
import Mobile from 'components/layout/Header/Mobile'
import Desktop from 'components/layout/Header/Desktop'

// Third Party
import styled, { css } from 'styled-components'

const Nav = styled(motion.nav)<{ isSticky: boolean }>`
  background-color: transparent;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 10;

  ${(props) =>
    props.isSticky &&
    css`
      background: rgba(0, 75, 150, 0.75);
      box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
      backdrop-filter: blur(4px);
    `}

  @media (min-width: 992px) {
    height: 110px;

    ${(props) =>
      props.isSticky &&
      css`
        background: rgba(0, 75, 150, 0.75);
        box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        height: 68px;
      `}
  }

  @media (max-width: 991.98px) {
    height: 68px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const Brand = styled(NavLink)`
  @media (min-width: 1200px) {
    & > svg {
      height: 53px;
      width: 220px;
    }
  }

  @media (max-width: 1199.98px) {
    & > svg {
      height: 37px;
      width: 160px;
    }
  }

  &:hover {
    opacity: 0.8;
  }
`

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
}

const Header: React.FC<HeaderProps> = ({
  setIsScrollable,
  isSticky = false,
}) => {
  // eslint-disable-next-line
  const query = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      wpComponent(databaseId: { eq: 97 }) {
        header {
          menuHeader {
            highlighted
            link {
              title
              url
              target
            }
            submenu {
              link {
                title
                url
                target
              }
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 100, width: 500)
                  }
                }
              }
              detailmenu {
                link {
                  title
                  url
                  target
                }
              }
            }
          }
          topmenuHeader {
            icon {
              localFile {
                publicURL
              }
            }
            link {
              title
              url
              target
            }
          }
          contactHeader {
            phone {
              title
              url
              target
            }
            email {
              title
              url
              target
            }
          }
        }
      }

      mobile: wpComponent(databaseId: { eq: 506 }) {
        fixedElements {
          menuimage {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 1000)
              }
            }
          }
        }
      }
    }
  `)

  const fields: any = query.wpComponent
  const mobile: any = query.mobile

  return (
    <Nav
      isSticky={isSticky}
      animate={isSticky ? { height: 68 } : { height: 110 }}
    >
      <Container className="container">
        <Brand to="/">
          <Logo />
        </Brand>

        <Mobile
          fields={fields}
          setIsScrollable={setIsScrollable}
          isSticky={isSticky}
          image={mobile.fixedElements.menuimage}
        />
        <Desktop fields={fields} isSticky={isSticky} />
      </Container>
    </Nav>
  )
}

export default Header
