import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from 'components/elements/Buttons/ButtonShell'

// Images
import Chevron from 'img/chevron-button.inline.svg'
import { motion } from 'framer-motion'

export const ButtonPrimaryCSS = css`
  position: relative;
  background-color: ${(props) => props.theme.color.secondary};
  display: inline-block;
  border-radius: 50px;
  overflow: hidden;

  & > a,
  & > button {
    position: relative;
    color: ${(props) => props.theme.color.light} !important;
    padding: 13px 20px;
    width: 100%;
    height: 100%;
    display: block;
    text-decoration: none !important;
    text-align: center;
    z-index: 2;
  }

  &:hover {
    & .wave {
      top: 0;
    }
  }

  & .wave {
    top: calc(100% + 22px);
    left: 0;
    background-color: #399974;
    transition: all 1s;
    z-index: -1;

    &:before {
      position: absolute;
      left: 0;
      content: '';
      width: 100%;
      height: 22px;
      background-image: url('/wave.png');
      top: -22px;
      animation: wavy 0.8s linear infinite;
    }
  }

  @keyframes wavy {
    0% {
      background-position-x: 0;
    }
    100% {
      background-position-x: 122px;
    }
  }
`

const StyledButton = styled(motion.span)<{
  minWidth: number
  fontWeight: string
  compact?: boolean
}>`
  ${ButtonPrimaryCSS};

  @media (min-width: 992px) {
    min-width: ${(props) => props.minWidth}px;
  }

  & > a,
  & > button {
    ${(props) =>
      props.compact &&
      css`
        padding: 10px 20px;
      `}

    ${(props) =>
      props.fontWeight === 'light' &&
      css`
        font-weight: ${({ theme }) => theme.font.weight.light};
      `}

    ${(props) =>
      props.fontWeight === 'semi' &&
      css`
        font-weight: ${({ theme }) => theme.font.weight.semiBold};
      `}

    ${(props) =>
      props.fontWeight === 'bold' &&
      css`
        font-weight: ${({ theme }) => theme.font.weight.bold};
      `}
  }
`

interface ButtonPrimaryProps {
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  className?: string
  target?: string
  chevron?: boolean
  minWidth?: number
  fontWeight?: 'light' | 'semi' | 'bold'
  compact?: boolean
}

const ButtonPrimary: React.FC<ButtonPrimaryProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  chevron,
  className = '',
  target = '',
  minWidth = 120,
  fontWeight,
  compact = false,
}) => {
  const [hovered, setHovered] = useState(false)

  return (
    <StyledButton
      className={className}
      minWidth={minWidth}
      fontWeight={fontWeight}
      compact={compact}
      onHoverStart={() => setHovered(true)}
      onHoverEnd={() => setHovered(false)}
    >
      {isCustom ? (
        <>
          {children}
          <div className="wave position-absolute w-100 h-100" />
          {chevron && (
            <motion.span
              animate={hovered ? { x: 30 } : { x: 20 }}
              className="d-inline-block"
            >
              <Chevron className="me-4" />
            </motion.span>
          )}
        </>
      ) : (
        <ButtonShell to={to} isAnchor={isAnchor} target={target}>
          {children}
          <div className="wave position-absolute w-100 h-100" />
          {chevron && (
            <motion.span
              animate={hovered ? { x: 30 } : { x: 20 }}
              className="d-inline-block"
            >
              <Chevron className="me-4" />
            </motion.span>
          )}
        </ButtonShell>
      )}
    </StyledButton>
  )
}

export default ButtonPrimary
