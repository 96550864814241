/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'

// Components
import NavLink, { isPartiallyActive } from 'components/shared/NavLink'

// Images
import Chevron from 'img/chevron-secondary.inline.svg'
import { AnimatePresence } from 'framer-motion'

interface SubmenuProps {
  // eslint-disable-next-line
  fields: any
  submenu: readonly GatsbyTypes.Maybe<GatsbyTypes.WpComponent_Header_menuHeader_submenu>[]
  title: string
}

const ItemCSS = css<{ active?: boolean }>`
  list-style: none;

  & > a {
    color: ${(props) => props.theme.color.dark};
    line-height: ${({ theme }) => theme.font.size.bigger};

    &[aria-current] {
      color: ${({ theme }) => theme.color.secondary};
    }

    &:hover {
      color: ${({ theme }) => theme.color.secondary};
      text-decoration: underline;
    }

    ${(props) =>
      props.active &&
      css`
        color: ${({ theme }) => theme.color.secondary};
      `}
  }

  @media (min-width: 992px) {
    & > a {
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 991px) {
    & > a {
      padding: 4px 0;
      font-size: 16px;
      line-height: 16px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
      color: ${({ theme }) => theme.color.light};
    }
  }
`

const Item = styled(motion.li)<{ active?: boolean }>`
  ${ItemCSS}

  & > a {
    font-size: ${({ theme }) => theme.font.size.big};
  }
`

const DetailItem = styled(motion.li)<{ active?: boolean }>`
  ${ItemCSS}

  & > a {
    font-size: ${({ theme }) => theme.font.size.medium};
  }
`

const StyledSubmenu = styled(motion.div)`
  overflow: hidden;

  @media screen and (min-width: 992px) {
    position: absolute;
    background-color: ${({ theme }) => theme.color.light};
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
    left: 0;
    min-height: 386px;
    overflow: hidden;
    border-bottom-right-radius: 50px;
  }

  @media screen and (max-width: 991px) {
    position: relative;
    width: 100%;

    & > div {
      padding: 2px 5px 2px 10px;
    }
  }
`

const ContactWrapper = styled.div`
  & h2 {
    color: ${({ theme }) => theme.color.light};
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }

  & a {
    color: ${({ theme }) => theme.color.secondary} !important;
    font-weight: ${({ theme }) => theme.font.weight.bold};

    &:hover {
      text-decoration: underline;
    }
  }
  background-color: ${({ theme }) => theme.color.primary};
`

const Image = styled(Plaatjie)`
  height: 297px;
`

const Submenu: React.FC<SubmenuProps> = ({ submenu, title, fields }) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentHover, setCurrentHover] = useState(-1)

  return (
    <StyledSubmenu
      initial={{ height: 0, opacity: 0, overflow: 'hidden' }}
      animate={{ height: 'fit-content', opacity: 1, overflow: 'hidden' }}
      exit={{ height: 0, opacity: 0, overflow: 'hidden' }}
      className="align-items-stretch overflow-hidden"
    >
      <div className="row">
        <div className="col-lg-4">
          <div className="py-3 ps-lg-4 py-lg-4 w-100 h-100">
            <h2 className="d-none d-lg-block font-weight-bold mb-4">{title}</h2>
            <ul className="d-block w-100">
              {submenu.map((item, index) => {
                const isPartiallyCurrent =
                  typeof window !== `undefined` &&
                  window.location.pathname.includes(item?.link?.url || '#')

                const isActive =
                  isPartiallyActive({
                    isPartiallyCurrent,
                    location: typeof window !== `undefined` && window.location,
                    href: item?.link?.url,
                  })['aria-current'] === 'page'

                return (
                  <motion.div
                    onHoverStart={() => {
                      setCurrentIndex(index)
                      setCurrentHover(index)
                    }}
                  >
                    <Item
                      key={index}
                      active={isActive}
                      className="d-flex align-items-center"
                    >
                      <NavLink
                        to={item?.link?.url || '/'}
                        className="d-flex align-items-center"
                      >
                        <motion.span
                          animate={
                            currentHover === index || isActive
                              ? { x: -5 }
                              : { x: -20 }
                          }
                          className="d-inline-block"
                        >
                          <motion.span
                            animate={
                              index === currentHover && item?.detailmenu
                                ? { rotate: 90 }
                                : { rotate: 0 }
                            }
                            className="d-none d-lg-inline-block"
                          >
                            <Chevron />
                          </motion.span>
                        </motion.span>
                        <span>{item?.link?.title}</span>
                      </NavLink>
                      {item?.detailmenu && (
                        <motion.span
                          onClick={() => setCurrentHover(index)}
                          animate={
                            index === currentHover
                              ? { rotate: -90, x: 20 }
                              : { rotate: 90, x: 20 }
                          }
                          className="d-lg-none d-inline-block"
                        >
                          <Chevron />
                        </motion.span>
                      )}
                    </Item>
                    <AnimatePresence>
                      {currentHover === index && item?.detailmenu && (
                        <motion.div
                          key={item?.detailmenu}
                          className="d-block overflow-hidden"
                        >
                          <div className="pb-4">
                            <ul className="d-block w-100">
                              {item?.detailmenu?.map((detail, detailIndex) => {
                                const isPartiallyCurrent =
                                  typeof window !== `undefined` &&
                                  window.location.pathname.includes(
                                    detail?.link?.url || '#'
                                  )

                                const isActive =
                                  isPartiallyActive({
                                    isPartiallyCurrent,
                                    location:
                                      typeof window !== `undefined` &&
                                      window.location,
                                    href: detail?.link?.url,
                                  })['aria-current'] === 'page'

                                return (
                                  <DetailItem
                                    key={`detail-${detailIndex}`}
                                    active={isActive}
                                  >
                                    <NavLink to={detail?.link?.url || '#'}>
                                      <motion.span
                                        animate={
                                          currentHover === index || isActive
                                            ? { x: -5 }
                                            : { x: -20 }
                                        }
                                        className="d-inline-block"
                                      >
                                        <Chevron />
                                      </motion.span>
                                      <span>{detail?.link?.title}</span>
                                    </NavLink>
                                  </DetailItem>
                                )
                              })}
                            </ul>
                          </div>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </motion.div>
                )
              })}
            </ul>
          </div>
        </div>
        <div className="col-4 d-none d-lg-flex">
          <div className="h-100 p-2">
            <div>
              <Plaatjie
                image={submenu[currentIndex]?.image}
                alt=""
                className="h-100"
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4 d-none d-lg-block">
          <ContactWrapper
            style={{ marginRight: -1 }}
            className="d-flex flex-column justify-content-between h-100 p-4"
          >
            <h2>Contact</h2>
            <div className="mb-4">
              <div>
                <NavLink to={fields.phone.url}>{fields.phone.title}</NavLink>
              </div>
              <div>
                <NavLink to={fields.email.url}>{fields.email.title}</NavLink>
              </div>
            </div>
          </ContactWrapper>
        </div>
      </div>
    </StyledSubmenu>
  )
}

export default Submenu
