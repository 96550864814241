import React from 'react'
import { motion } from 'framer-motion'

// Components
import Menu from 'components/layout/Header/Menu'

// Third Party
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'
import { AnimatePresence } from 'framer-motion'
import Topmenu from './Topmenu'

const MenuWrapper = styled(motion.div)`
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2;

  & > div {
    width: 100vw;
    height: 100vh;
    z-index: 99;
  }

  & ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
    width: 70%;
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${(props) => props.theme.color.light};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 2px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonStripe = styled.div`
  background-color: ${(props) => props.theme.color.light};
  height: 2px;
  width: 50%;
  margin: 5px auto;
`

const BGWrapper = styled.div`
  top: 0;
  bottom: 0;
  z-index: -1;
`

const Gradient = styled.div`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(31, 38, 135, 0.45);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border: 6px solid rgba(255, 255, 255, 0.18);
`

interface MobileProps {
  fields: GatsbyTypes.WpComponent
  isSticky?: boolean
  image?: any
  setIsScrollable: Function
}

const Mobile: React.FC<MobileProps> = ({ fields, image, setIsScrollable }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  return (
    <AnimatePresence>
      <div className="d-block d-lg-none">
        <NavButton
          role="button"
          onClick={() => {
            setIsOpen(!isOpen)
            setIsScrollable(false)
          }}
        >
          <NavButtonStripe />
          <NavButtonStripe />
          <NavButtonStripe />
        </NavButton>
        {isOpen && (
          <AnimatePresence>
            <MenuWrapper
              initial={{ y: '100vw' }}
              animate={{ y: '0vw' }}
              exit={{ y: '100vw' }}
            >
              <div className="position-relative d-flex flex-column justify-content-center align-items-center">
                <BGWrapper className="position-absolute">
                  <Plaatjie image={image} alt="" className="h-100" />
                </BGWrapper>
                <Gradient className="position-absolute h-100" />
                <NavButtonClose
                  role="button"
                  onClick={() => {
                    setIsOpen(!isOpen)
                    setIsScrollable(true)
                  }}
                >
                  <NavButtonCloseCross />
                </NavButtonClose>
                <ul className="position-relative">
                  <Menu fields={fields} />
                </ul>
                <ul className="mt-5">
                  <Topmenu fields={fields} />
                </ul>
              </div>
            </MenuWrapper>
          </AnimatePresence>
        )}
      </div>
    </AnimatePresence>
  )
}

export default Mobile
